<template>
  <svg width="284px" height="284px" viewBox="0 0 284 284" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path transform="translate(53.000000, 53.000000)" fill="#A01E1D" d="M103.062233,88.9997944 L145.256511,46.8055167 C149.134844,42.9271833 149.134844,36.63235 145.256511,32.7440722 C141.368233,28.8657389 135.0734,28.8657389 131.195067,32.7440722 L89.0007889,74.93835 L46.8065111,32.7440722 C42.9281778,28.8657389 36.6333444,28.8657389 32.7450667,32.7440722 C28.8667333,36.63235 28.8667333,42.9271833 32.7450667,46.8055167 L74.9393444,88.9997944 L32.7450667,131.194072 C28.8667333,135.08235 28.8667333,141.377183 32.7450667,145.255517 C34.6941778,147.204628 37.2300111,148.169239 39.7757889,148.169239 C42.3215667,148.169239 44.8673444,147.204628 46.8065111,145.255517 L89.0007889,103.071183 L131.195067,145.255517 C133.134233,147.204628 135.680011,148.169239 138.225789,148.169239 C140.771567,148.169239 143.3074,147.204628 145.256511,145.255517 C149.134844,141.377183 149.134844,135.08235 145.256511,131.194072 L103.062233,88.9997944 Z"></path>
  </svg>
</template>

<script>
export default {
name: "error-icon"
}
</script>